<template>
    <div>
        <md-toolbar class="md-primary mb-2" md-elevation="1">
            <h3 class="md-title" style="flex: 1">User settings</h3>
        
           <!--md-button class="md-primary md-raised" @click="search">Search partner</md-button-->
        
        </md-toolbar>
      <div v-show="isBusy">
        <md-progress-bar md-mode="query" ></md-progress-bar> 
      </div>
      <div v-show="!isBusy">
        <md-tabs>
            <md-tab id="phone-sign-in" md-label="Phone sign in">
                <CRow class="mb-4">
                    
                    <CCol sm="3" md="6">
                        <md-card>
                            <md-card-header>
                                <h1 class="md-title">Sign in with phone number</h1>
                                <md-switch v-model="phoneSignIn" class="md-primary" @change="phoneSignInChanged">Enable</md-switch>
                            </md-card-header>
                            <md-card-content v-show="phoneSignIn">
                                <md-field >
                                    <label>Phone</label>
                                    <md-input v-model="user.data.phoneNumber" :disabled="phoneVerificationId"></md-input>  
                                </md-field>
                                <md-field :class="getValidationClass('verification')" v-if="phoneVerificationId" >
                                    <label>Enter verification code</label>
                                    <md-input v-model="phoneVerification" @keyup="checkPhoneVerificationCode"></md-input>
                                    <span class="md-error" v-if="!$v.phoneVerification.required">Verification is required</span>
                                    <span class="md-error" v-if="!$v.phoneVerification.minLength">Enter 6 digits combination</span>
                                
                                </md-field>
                                <md-field :class="getValidationClass('recaptchaPhone')" class="no-line" >
                                    <div id="recaptcha-container-phone"></div>
                                            
                                    <span class="md-error" v-if="!$v.recaptchaPhone.required">reCAPTCHA is required</span>
                                </md-field>
                                <span class="md-helper-text up-text-cancelled" v-if="phoneVerificationError">{{ phoneVerificationError }}</span>
                                <span class="md-helper-text up-text-cancelled" v-if="mainInfo.error">{{ mainInfo.error }}</span>
                                <md-button class="md-raised full-width-button md-primary" @click="firePhoneSignIn" :disabled="sendingPhoneSignInVerification || checkingPhoneVerificationCode">
                                    {{ !sendingPhoneSignInVerification  && !phoneVerificationId? 'Send SMS' : ''}}
                                    {{ sendingPhoneSignInVerification ? 'Sending SMS' : ''}}
                                    {{ phoneVerificationId && !checkingPhoneVerificationCode ? 'Resend SMS' : '' }}
                                    {{ checkingPhoneVerificationCode ? 'Checking verification code' : '' }}
                                    <md-progress-bar md-mode="indeterminate" v-show="sendingPhoneSignInVerification || checkingPhoneVerificationCode"></md-progress-bar>
                                </md-button>
                            
                            </md-card-content>
                        </md-card>
                    </CCol>
                </CRow>

            </md-tab>

            <md-tab id="multi-factor-authentication" md-label="Multi Factor Authentication">
                <CRow class="mb-4">
                    <CCol sm="3" md="6">
                        <md-card>
                            <form action="#" @submit.prevent="submit">
                                <md-card-header>
                                    <h1 class="md-title">Multi Factor Authentication</h1>
                                    <md-switch v-model="mfa" class="md-primary">Enable</md-switch>
                                </md-card-header>
                                <md-card-content v-show="mfa">
                                    <CRow>
                                        <CCol sm="12">
                                        
                                            <md-field :class="getValidationClass('phone')">
                                                <label>Phone</label>
                                                <md-input v-model="phone"></md-input>
                                                <span class="md-error" v-if="!$v.phone.required">Phone is required</span>
                                                <span class="md-helper-text md-subheading" v-if="smsSent">SMS sent successfully</span>
                                            </md-field>
                                            <md-field :class="getValidationClass('verification')" v-if="verificationId" >
                                                <label>Enter verification code</label>
                                                <md-input v-model="verification" @keyup="checkVerificationCode"></md-input>
                                                <span class="md-error" v-if="!$v.verification.required">Verification is required</span>
                                                <span class="md-error" v-if="!$v.verification.minLength">Enter 6 digits combination</span>
                                                <span class="md-helper-text up-text-cancelled" v-if="verificationError">{{ verificationError }}</span>
                                            </md-field>
                                            <md-field :class="getValidationClass('recaptcha')" class="no-line" v-show="!smsSent">
                                                <div id="recaptcha-container"></div>
                                                        
                                                <span class="md-error" v-if="!$v.recaptcha.required">reCAPTCHA is required</span>
                                            </md-field>
                                            <md-field class="md-invalid" v-if="error">
                                                <span class="md-error md-subheading">{{ error }}</span>
                                            </md-field>
                                        </CCol>
                                                    
                                    </CRow>


                                    <!--md-button class="md-raised full-width-button md-primary" type="submit" >Send SMS</md-button-->
                                    
                                    <md-button class="md-raised full-width-button md-primary" type="submit" :disabled="sendingMfaCode || checkingMfaCode">
                                        {{ !sendingMfaCode  && !verificationId? 'Send SMS' : ''}}
                                        {{ sendingMfaCode ? 'Sending SMS' : ''}}
                                        {{ verificationId && !checkingMfaCode ? 'Resend SMS' : '' }}
                                        {{ checkingMfaCode ? 'Checking verification code' : '' }}
                                        <md-progress-bar md-mode="indeterminate" v-show="sendingMfaCode || checkingMfaCode"></md-progress-bar>
                                    </md-button>



                                </md-card-content>
                            </form>
                        </md-card>
                    </CCol>
                </CRow>
            </md-tab>

            <md-tab id="roles-managment" md-label="Roles Managment">
                <CRow class="mb-4">
                    <CCol sm="12" md="6">

                        <md-field >
                            <label for="role">Active Role</label>
                            <md-select v-model="user.data.activeRole" name="role" id="role" @md-selected="roleSelected()" :disabled="true">
                                <md-option v-for="role in roles" :value="role.name" v-bind:key="role.name">{{ role.text }}</md-option>
                                
                            </md-select>
                        </md-field>
                        <md-field  v-if="user.data.activeSubrole">
                            <label for="role">Active Sub Role</label>
                            <md-select v-model="user.data.activeSubrole" name="subrole" id="subrole"  :disabled="true">
                                <md-option v-for="subrole in subroles" :value="subrole.name" v-bind:key="subrole.name">{{ subrole.text }}</md-option>
                                
                            </md-select>
                        </md-field>
                    </CCol>
                    <CCol sm="12" md="6">
                        <md-card>
                            <md-card-header>
                                <div class="md-title">Available roles</div>
                            </md-card-header>

                             <md-card-content>

                                <md-list>
                                    <md-list-item v-for="(role,index) in user.data.roles" v-bind:key="role.slug + index">
                                        <span class="md-list-item-text">{{  role.subrole ? role.subrole.label : '' }} {{ role.label}}</span>
                                        <div class="md-list-action"  v-if="role.slug === user.data.activeRole && !user.data.activeSubrole">
                                            <md-icon class="md-primary md-size-2x">toggle_on</md-icon>
                                            <!--md-chip class="md-list-action md-raised md-primary" >
                                                 Active
                                            </md-chip-->
                                        </div>
                                        <div class="md-list-action"  v-else-if="role.slug === user.data.activeRole && (role.subrole.slug === user.data.activeSubrole)">
                                            <md-icon class="md-primary md-size-2x">toggle_on</md-icon>
                                            <!--md-chip class="md-list-action md-raised md-primary" >
                                                 Active
                                            </md-chip-->
                                        </div>
                                        <div v-else class="md-list-action">

                                            <md-button class="md-raised"  @click="switchRol(role.slug, role.subrole ? role.subrole.slug : null)">
                                                 <md-icon class="">toggle_off</md-icon>
                                                 Switch
                                            </md-button>
                                            <md-button class="md-raised md-accent" @click="removeRole(role,index)">Remove role</md-button>
                                        </div>

                                    </md-list-item>
                                    <md-list-item>
                                        <md-button class="md-raised full-width-button md-primary" @click="newRole.adding = true" v-if="!newRole.adding && availableRoles.length > 0">Add new role</md-button>
                                        <md-button class="md-raised md-primary full-width-button" :disabled="true" v-else>No more roles possible</md-button>
                                        
                                    </md-list-item>
        
                                    
                                </md-list>
                                <div v-if="newRole.adding">
                                    <md-field :class="getValidationClass('newRole.role')">
                                        <label for="role">Role</label>
                                        <md-select v-model="newRole.role" name="role" id="role" @md-selected="roleSelected()" >
                                            <md-option v-for="role in availableRoles" :value="role.name" v-bind:key="role.name">{{ role.text }}</md-option>
                                            
                                        </md-select>
                                        <span class="md-error" v-if="!$v.newRole.role.required">Role is required</span>
                                    </md-field>
                                    <md-field  v-if="newRole.role === 'driver'" :class="getValidationClass('newRole.subrole')">
                                        <label for="role">Sub Role</label>
                                        <md-select v-model="newRole.subrole" name="subrole" id="subrole" >
                                            <md-option v-for="subrole in availableSubRoles" :value="subrole.name" v-bind:key="subrole.name">{{ subrole.text }}</md-option>
                                            
                                        </md-select>
                                        <span class="md-error" v-if="!$v.newRole.subrole.required">Subrole is required</span>
                                    </md-field>
                                    <md-button class="md-raised full-width-button" @click="newRole.adding = false"  v-if="newRole.adding">Cancel</md-button>
                                    <md-button class="md-raised full-width-button md-primary"  v-if="newRole.adding" @click="addNewRole()">Save</md-button>
                                </div>
                            </md-card-content>
                        </md-card>

                    </CCol>
                </CRow>
            </md-tab>
        </md-tabs>

       

        <md-dialog-confirm
            :md-active.sync="phoneSignInDialogActive"
            md-title="Are you sure you want to disable phone sign in?"
            md-content="Phone sign can be switched and changed as many times as you prefer"
            md-confirm-text="Disable"
            md-cancel-text="Cancel"
            @md-cancel="onPhoneSignInCancel"
            @md-confirm="onPhoneSignInConfirm" />
      </div>
      <md-snackbar :md-position="position" :md-duration="isInfinity ? Infinity : duration" :md-active.sync="showSnackbar" md-persistent>
          <span>{{snackMessage}}</span>
          <md-button class="md-primary" @click="showSnackbar = false">OK</md-button>
      </md-snackbar>
    </div>
  </template>
  
  <script>
  

  import moment from "moment";
  import moment_timezone from "moment-timezone";
  import axios from "./../../helpers/axios"
  import { validationMixin } from 'vuelidate'
    import {
        required,
        between,
        email,
        minLength,
        maxLength,
    requiredIf,
    } from 'vuelidate/lib/validators'
  
  export default {
    name: "UserSettings",
    components: {
      
    },
    mixins: [validationMixin],
    computed: {
      user() {
        return this.$store.state.user;
      },
      availableRoles() {
        let result = []
        result = this.roles.filter((role) => {
            let exists = []
            let driverSubRoles = []
            console.log(role.name)
            for (let i = 0; i < this.user.data.roles.length; i++) {
                if(this.user.data.roles[i].slug === 'driver' && role.name === 'driver') {
                    driverSubRoles.push(true)
                } else if(this.user.data.roles[i].slug === role.name){
                    exists.push(true)
                }
                
            }
            console.log(driverSubRoles)
            if(driverSubRoles.length < 2 && role.name === 'driver') {
                return role
            }

            if(!exists.includes(true) && role.name != 'driver') {
                return role
            }
        })

        return result
      },
      availableSubRoles() {
        let result = []
        result = this.subroles.filter((subrole) => {

            let exists = []
            for (let i = 0; i < this.user.data.roles.length; i++) {
                if(this.user.data.roles[i].subrole && this.user.data.roles[i].subrole.slug === subrole.name)  {
                    exists.push(true)
                }
            }

            if(!exists.includes(true)) {
                return subrole
            }
        })

        return result

      }
      
    },
    mounted() {
  
      //this.updateClock();
      this.obtainViewResources()
    },
    data() {
      return {
        isBusy: true,
        smsSent: false,
        recaptcha: null,
        error: null,
        mfa: false,
        phone: null,
        multiFactorSession: null,
        verificationId: null,
        recaptchaVerifier: null,
        apllicationVerifier: null,
        verification: null,
        verificationError: null,
        mainInfo: {
            phoneNumber: null,
            displayName: null,
            error:null

        },
        sendingMfaCode: null,
        checkingMfaCode: null,

        recaptchaPhone: null,
        recaptchaPhoneVerifier: null,
        apllicationPhoneVerifier: null,
        phoneSignIn: false,
        phoneVerificationId: null,
        phoneVerification: null,
        phoneVerificationError: null,
        phoneSignInDialogActive: false,
        showSnackbar: false,
        position: 'center',
        duration: 7000,
        isInfinity: false,
        snackMessage: '',
        sendingPhoneSignInVerification: false,
        checkingPhoneVerificationCode: false,
        roles: [
          
            {
                name: 'company-admin',
                text: 'Affiliated company admin'
            },
            {
                name: 'driver',
                text: 'Driver'
            },
            {
                name: 'dispatcher',
                text: 'Dispatcher'
            },
            {
                name: 'affiliate',
                text: 'Affiliate'
            },
            {
                name: 'provider',
                text: 'Provider'
            },
            {
                name: 'client',
                text: 'Corporate client'
            },
            
        ],
        subroles: [
          
            {
                name: 'company',
                text: 'Company'
            },
            {
                name: 'owner-operator',
                text: 'Owner operator'
            },
            
        ],
        newRole: {
            adding: false,
            role: null,
            subrole: null
        },
        claimsExample: {
            activeRole: 'company-admin',
            roles: [
                {
                    slug: 'company-admin',
                },
                {
                    slug: 'driver',
                }
            ]
        }
  
      };
    },
    methods: {
        resetNewRoleAdd(){
            this.newRole = {
                adding: false,
                role: null,
                subrole: null
            }
        },
        removeRole(role,index) {
            this.isBusy = true
            this.user.data.roles.splice(index,1)
            let rolesToSave = this.user.data.roles
            let data = {
                claims: {
                    roles: rolesToSave
                }
            }
            let th = this

            console.log(data)

            axios.patch(`users/${this.user.data.uid}`, data).then(response =>{
                console.log(response);                 
                this.isBusy = false
                th.snackMessage = 'Role removed successfully';
                th.showSnackbar = true;
                this.resetNewRoleAdd()
                
            }).catch(e => {
                this.isBusy = false
                th.snackMessage = 'Oops, something went wrong. Try again';
                th.showSnackbar = true;
                this.resetNewRoleAdd()
            })

        },

        addNewRole() {
            if(this.validate()) {
                this.isBusy = true
                console.log('valid')
                let roleText = null
                let rolesToSave = this.user.data.roles
                let th = this

                switch(this.newRole.role) {
                    case 'company-admin':
                        roleText = 'Company admin'
                        break;
                    case 'driver':
                        roleText = 'Driver'
                        break;
                    case 'dispatcher':
                        roleText = 'Dispatcher'
                        break;
                    case 'affiliate':
                        roleText = 'Affiliate'
                        break;
                    case 'provider':
                        roleText = 'Provider'
                        break;
                }
                let newRole = {
                    label:  roleText,
                    slug: this.newRole.role
                }

                let subroleText = null

                if(this.newRole.subrole) {
                    switch(this.newRole.subrole) {
                        case 'company':
                            subroleText = 'Company'
                            break;
                        case 'owner-operator':
                            subroleText = 'Owner operator'
                            break;
                        
                    }

                    let subrole = {
                        slug: this.newRole.subrole,
                        label: subroleText
                    }

                    newRole.subrole = subrole
                }

                rolesToSave.push(newRole)

                let data = {
                    claims: {
                        roles: rolesToSave
                    }
                }

                console.log(data)

                axios.patch(`users/${this.user.data.uid}`, data).then(response =>{
                    console.log(response);                 
                    this.isBusy = false
                    th.snackMessage = 'Role added successfully';
                    th.showSnackbar = true;
                    this.resetNewRoleAdd()
                    
                }).catch(e => {
                    this.isBusy = false
                    th.snackMessage = 'Oops, something went wrong. Try again';
                    th.showSnackbar = true;
                    this.resetNewRoleAdd()
                 })

            } else {
                this.$v.$touch()
            }
        },
        async refreshUserToken() {
            let th = this
            return new Promise((resolve, reject) => {
                // this.$firebase.auth().revokeRefreshTokens(th.user.data.uid)
                // .then(() => {
                //     resolve()
                // })
                // this.$firebase.auth().currentUser.getIdTokenResult().then(payload => {
                //     console.log(payload)
                //     payload.refreshToken = th.user.data.refreshToken
                //     th.$store.dispatch("fetchUser", payload);
                //     //store.dispatch("refreshToken");
                //     resolve()
                // }).catch(function(error) {
                //     //Handle error
                //     reject()
                // });
                // this.$firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then(function(idToken) {
                //     console.log(idToken);
                //     th.$store.commit("setRefreshToken",idToken);
                //     //th.$store.commit("setAccessToken", idToken);
                //     resolve(idToken)
                // }).catch(function(error) {
                //     reject(error)
                // });
                
            })
        },
        async verifyUserToken(idToken) {
            let th = this
            return new Promise((resolve, reject) => {
                let data = {
                    idToken
                }
                axios.patch(`verify-user-token`, data).then(response =>{
                    console.log(response); 
                    resolve()         
                }).catch(e => {
                    this.isBusy = false
                    th.snackMessage = 'Oops, something went wrong. Try again';
                    th.showSnackbar = true;
                    reject()
                })
            })
        },
        async switchRoleClaims(data){
            let th = this
            return new Promise((resolve, reject) => {
                
            
                console.log(data)

                axios.patch(`users/${this.user.data.uid}`, data).then(response =>{

                    console.log(response);           
                    th.user.data.activeRole =   response.data.user.customClaims.activeRole
                    th.user.data.activeSubrole =   response.data.user.customClaims.activeSubrole
                    //th.$store.commit('set', ['user.data',response.data.user.customClaims.activeRole ])
                    //th.$store.commit('set', ['user.data',response.data.user.customClaims.activeSubrole ]) 
                    th.$store.dispatch("refreshToken");
                    resolve()
                
                }).catch(e => {
                    this.isBusy = false
                    th.snackMessage = 'Oops, something went wrong. Try again';
                    th.showSnackbar = true;
                    reject()
                })
                
            })
        },
        async switchRol(role,subrole) {
            this.isBusy = true
           
           
            let data = {
                claims: {
                    activeRole: role,
                    activeSubrole: subrole
                }
            }

            await this.switchRoleClaims(data)
            //const token = await this.refreshUserToken()
            //await this.verifyUserToken(token)


            this.isBusy = false
            this.snackMessage = 'Role switched successfully';
            this.showSnackbar = true;
            
        },
        roleSelected() {

        },
        onPhoneSignInCancel() {
            this.phoneSignInDialogActive = false
            this.phoneSignIn = true
        }, 
        async onPhoneSignInConfirm() {
            await this.disablePhoneSignIn()
        },
        phoneSignInChanged(val) {
            console.log(val)
            if(!val) {
                this.phoneSignInDialogActive = true
            } else {
                this.phoneSignIn = true
            }
        },
        async saveMainInfo() {
            this.mainInfo.error = null
            let data = {
                phoneNumber: this.user.data.phoneNumber,
            }

            this.isBusy = true

            axios.patch(`users/${this.user.data.uid}`, data).then(response =>{
              console.log(response);
              
              this.isBusy = false
            }).catch(e => {
              console.log(e);  
              console.log(e.response);  
              let finalText = null;
              switch(e.response.data.message) {
                case 'auth/invalid-phone-number - TOO_LONG':
                    finalText = 'Invalid phone number typed. Number is too long'
                    break
                case 'auth/invalid-phone-number - TOO_SHORT':
                    finalText = 'Invalid phone number typed. Number is too short'
                    break
                default:
                    finalText = e.response.data.message

              }
              this.mainInfo.error = finalText
              this.isBusy = false
                   
            })
        },
        validate(){
                let role,subrole;
                role = this.$v.newRole.role.$invalid;
                subrole = this.$v.newRole.subrole.$invalid;
                

                
                return !role && !subrole ? true : false ;


            },
        async checkVerificationCode() {
            if(this.verification.length == 6) {
                this.checkingMfaCode = true
                var cred = this.$firebase.auth.PhoneAuthProvider.credential(this.verificationId, this.verification);
                console.log(cred)
                var multiFactorAssertion = this.$firebase.auth.PhoneMultiFactorGenerator.assertion(cred);
                // Complete enrollment. This will update the underlying tokens
                // and trigger ID token change listener.
                const user = await this.$firebase.getCurrentUser()
                user.multiFactor.enroll(multiFactorAssertion, 'My personal phone number');
                this.checkingMfaCode = false

            }

        },
        async getMultiFactorSession(user) {
            let th = this
            return new Promise((resolve, reject) => {
                user.multiFactor.getSession().then(function(multiFactorSession) {
                    console.log(multiFactorSession)
                    th.multiFactorSession = multiFactorSession ;
                    resolve(multiFactorSession)
                })
            })
        },
        async firePhoneSignIn() {
           
            const smsSent = await this.sendSmsVerificationCodePhoneSignIn()
            
           
        },
        async checkPhoneVerificationCode() {
                if(this.phoneVerification.length === 6) {
                    
                    let th = this
                    this.phoneVerificationError = null
                    this.checkingPhoneVerificationCode = true
                    let data = {
                        code: this.phoneVerification, 
                        role: this.user.data.activeRole,
                        email: this.user.data.email,
                        phoneNumber: this.user.data.phoneNumber,
                        uid: this.user.data.uid,
                    }

                    return new Promise((resolve, reject) => {
                        axios.post('users/check-verification-sms-code',data).then(response =>{
                            console.log(response)
                            this.checkingPhoneVerificationCode = false
                            this.phoneVerificationId = null
                            this.phoneVerification = null
                            th.snackMessage = 'Code checked successfully';
                            th.showSnackbar = true;
                            resolve()
                        }).catch(e => {
                            th.phoneVerificationError = e.response.data.message
                            this.checkingPhoneVerificationCode = false
                            th.snackMessage = e.response.data.message;
                            th.showSnackbar = true;
                            reject()
                        })
                    })
                }
           
        },
        disablePhoneSignIn() {
            let th = this
            this.isBusy = true
            let data = {
                phoneNumber: null, 
                role: this.user.data.activeRole,
                email: this.user.data.email,
                uid: this.user.data.uid,
            }

            return new Promise((resolve, reject) => {
                axios.post('users/disable-phone-sign-in',data).then(response =>{
                    console.log(response)
                    th.snackMessage = 'Phone sign in disabled successfully';
                    th.user.data.phoneNumber = null
                    th.isBusy = false;
                    th.showSnackbar = true;
                    
                }).catch(e => {
                    th.snackMessage = 'Something went wrong. Try again please';
                    th.isBusy = false;
                    th.showSnackbar = true;
                })
            })
        },
        async sendSmsVerificationCodePhoneSignIn() {
            let th = this
            let data = {
                phoneNumber: this.user.data.phoneNumber, 
                recaptchaToken: this.recaptchaPhoneVerifier,
                role: this.user.data.activeRole,
                email: this.user.data.email,
                uid: this.user.data.uid,
            }

            this.sendingPhoneSignInVerification = true
            this.phoneVerificationError = null
            return new Promise((resolve, reject) => {
                axios.post('users/send-verification-sms',data).then(response =>{
                    console.log(response)
                    th.phoneVerificationId = true
                    this.sendingPhoneSignInVerification = false
                    resolve()
                }).catch(e => {
                    this.sendingPhoneSignInVerification = false
                    this.phoneVerificationError = e.response.data.message
                    reject()
                })
            })
        },

        async sendSmsVerificationCode(phoneInfoOptions,applicationVerifier) {
            let th = this
            console.log(applicationVerifier);
            this.sendingMfaCode = true
            return new Promise((resolve, reject) => {
                var phoneAuthProvider = new this.$firebase.auth.PhoneAuthProvider();
                // Send SMS verification code.
                 phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, applicationVerifier)
                .then(function(verificationId) {
                    th.verificationId = verificationId
                     //verificationId will be needed for enrollment completion.
                     th.sendingMfaCode = false
                     resolve()
                }).catch(function(error){
                    th.applicationVerifier.clear()
                    th.error = error.message
                    th.sendingMfaCode = false
                    reject()
                })
            })
           
        },
        async submit() {
            console.log(this.$firebase.getCurrentUser());
            const user = await this.$firebase.getCurrentUser()

            let th = this
            console.log(user)
            
            const multiFactorSession = await this.getMultiFactorSession(user)

            var phoneInfoOptions = {
                phoneNumber: this.phone,
                session: multiFactorSession
            };

            console.log(phoneInfoOptions)

           await this.sendSmsVerificationCode(phoneInfoOptions, this.applicationVerifier)





        },
    //   initRecaptcha(id,apllicationVerifierName, recaptchaVerifierName, recaptchaWidgetIdName) {
    //     let th = this;
    //     this.applicationVerifier = new this.$firebase.auth.RecaptchaVerifier(
    //         'recaptcha-container',
    //         {
    //             'size': 'normal',
    //             'callback': function(response) {
                    
    //                 th.recaptchaVerifier = response
    //             },
    //             'expired-callback': function() {
    //                 // Response expired. Ask user to solve reCAPTCHA again.
    //                 // ...
    //             }
    //         });
        
    //         this.applicationVerifier.render()
    //                 .then(function(widgetId) {
    //                     window.recaptchaWidgetId = widgetId;
    //                 });

    //   },
      initRecaptcha(id,apllicationVerifierName, recaptchaVerifierName, recaptchaWidgetIdName) {
        let th = this;
        this[apllicationVerifierName] = new this.$firebase.auth.RecaptchaVerifier(
            id,
            {
                'size': 'normal',
                'callback': function(response) {
                    
                    th[recaptchaVerifierName] = response
                },
                'expired-callback': function() {
                    // Response expired. Ask user to solve reCAPTCHA again.
                    // ...
                }
            });
        
            this[apllicationVerifierName].render()
                    .then(function(widgetId) {
                        window[recaptchaWidgetIdName] = widgetId;
                    });

      },
      async getUserDoc() {
        let th = this
        return new Promise((resolve, reject) => {
            let userDocRef
           

            userDocRef = th.$firebase.firestore().collection('users').doc(th.user.data.uid)

            userDocRef.onSnapshot(doc => {
                console.log(doc.data());
                if(doc.exists) {
                    let data = doc.data()
                    if(data.sessionInfo) {

                    }
                    data.sessionInfo ? th.phoneSignIn = true : th.phoneSignIn = data.phoneVerified
                    
                    resolve()
                } else {
                    reject()
                }
            });
        })
      },
      async obtainViewResources(){
          this.isBusy = true
          this.initRecaptcha('recaptcha-container','applicationVerifier','recaptchaVerifier','recaptchaWidgetId')
          this.initRecaptcha('recaptcha-container-phone','apllicationPhoneVerifier','recaptchaPhoneVerifier','recaptchaWidgetIdPhone')
          await this.getUserDoc()
          //this.initRecaptcha()
          this.isBusy = false
      },
      getValidationClass (fieldName) {
        let field = null;

        if (
            fieldName == 'newRole.role' 
            || fieldName == 'newRole.subrole' 
            
            ) {
            let fieldSplit = fieldName.split('newRole.')[1];
            field = this.$v.newRole[fieldSplit]
        } else {

            field = this.$v[fieldName];
        }
        
        
        
        if (field) {
            return {
                'md-invalid': field.$invalid && field.$dirty
            }
        }
     },
     
    },
    watch: {
        'newRole.role': {
            handler(val,oldVal) {
                this.newRole.subrole = null
            },
            deep: true

         },
    },
    validations: {
        recaptcha: {
             required
        },
        recaptchaPhone: {
             required
        },
        phone: {
            required: requiredIf(function(vue){
                return !this.emailLogin ? true : false ;
            })
        },
        verification: {
             required: requiredIf(function(vue){
                return !this.emailLogin && this.smsSent ? true : false ;
             }),
            minLength: minLength(6)
        },
        phoneVerification: {
             required: requiredIf(function(vue){
                return !this.emailLogin && this.smsSent ? true : false ;
             }),
            minLength: minLength(6)
        },

        newRole: {
            role: {
                required
            },
            subrole: {
                required: requiredIf(function(){
                    return this.newRole.role === 'driver' ? true : false ;
                })
            }
        }
    }
  };
  </script>
  